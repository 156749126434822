<!--
<addItem>
	<template #default='{ data }'>
		{{ data.v }} {{ data.i }}
	</template>
</addItem>
-->

<template>
	<div>
		<div v-for="(v, i) in addItemList" class="flex my-2">
			<slot :data="{ v, i }" />
			<b-button class="ml-3 px-4" @click="remove(i)" variant="gray">-</b-button>
			<b-button v-if="i + 1 == allLength" class="ml-3 px-4" @click="addItemList.push({ ...defaultAddItemList })"
				>+
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	props: { cName: { default: 'addItem' } },
	data() {
		return {
			defaultAddItemList: {},
			addItemList: [],
		}
	},
	methods: {
		init(defaultItemList) {
			this.defaultAddItemList = defaultItemList
			this.addItemList = [{ ...defaultItemList }]
		},
		getItem(ignoreSpace = false) {
			if (ignoreSpace) return this.addItemList
			return this.addItemList.filter(v => {
				let pass = false
				Object.keys(this.defaultAddItemList).map(k => {
					if (v[k]) pass = true
				})
				return pass
			})
		},
		remove(idx) {
			if (this.allLength != 1) this.addItemList.splice(idx, 1)
		},
	},
	computed: {
		allLength() {
			return this.addItemList.length
		},
	},
}
</script>
